import React, { Component } from "react";
import { Nav } from "../Components/Nav";
import { HeaderContact } from "../Components/HeaderContact/HeaderContact";

// Email validation
const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

// Form validation
const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // Validate form errors being empty
    Object.values(formErrors).forEach((val) => {
        val.length > 0 && (valid = false);
    });

    // Validate the form was filled out
    Object.values(rest).forEach((val) => {
        val === '' && (valid = false);
    });

    return valid;
};


class Contact extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            formErrors: {
                name: '',
                email: '',
                subject: '',
                message: '',
            },
        };
    }

    initialState = this.state


    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
            formErrors: {
                name: '',
                email: '',
                subject: '',
                message: '',
            },
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        fetch('http://localhost:3002/send', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
        ).then((response) => {
            if (response.status === 'success') {
                alert("Message Sent.");
                this.resetForm()
            } else if (response.status === 'fail') {
                alert("Message failed to send.")
            }
        })
    }

    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case 'name':
                formErrors.name = value.length < 1 ? 'Please enter your name.' : '';
                break;
            case 'email':
                formErrors.email = emailRegex.test(value) ? '' : 'Please enter a valid email address.';
                break;
            case 'subject':
                formErrors.subject = value.length < 1 ? 'Please enter a subject.' : '';
                break;
            case 'message':
                formErrors.message = value.length < 1 ? 'Please enter a message' : '';
                break;
            default:
                break;
        }
        this.setState({ formErrors, [name]: value });
    };

    render() {
        return (
            <>
            <Nav />
                <HeaderContact />
                <div className="container mg-t-minus10">
                    <div className="card contact-card">
                        <div className="row">

                            <div className="col-6">
                                One of three columns
                            </div>

                            <div className="col-6">
                                <div className="contact">
                                    <form id="contact-form" onSubmit={this.handleSubmit} noValidate>
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                                        </div>
                                        <button type="submit" className="btn btn-outline-orange">Submit</button>
                                    </form>
                                </div>
                            </div>      

                        </div>
                    </div>
                </div>
            </>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

}

export default Contact;