import React from 'react'
import styled from 'styled-components'
import { Button } from '../Common/Button'
import { config } from "../../config"
import { colors } from '../Theme/colors'
import firstmovers from './img/first-movers.png'
import curio from './img/curio.png'


export const Projects = () => {
    return (
            <Section className="bg-projects">

                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <Headline className="text-center">PROJECTS</Headline>
                            <Title className="text-center">Developing interfaces to make data readable and educational</Title>
                        </div>

                        <ProjectCard className="col-12 col-sm-12 col-md-12 col-xl-6 mb-5">
                            <CardSection>
                                <div className="col-12">
                                    <img className="mb-3 img-border" src={firstmovers} />
                                    <ProjectTitle>First Movers Club - NFT Collection</ProjectTitle>
                                    <ProjectTags>#blender3d #python #react #thirdWeb #ipfs #infura</ProjectTags>
                                    <ProjectDescription className="mt-2">
                                    The First Movers are a NFT Collection of 1,111 randomly generated ERC-721 tokens living on the Polygon blockchain. 
                                    With 320+ colorful traits, every First Mover is individual and unique.
                                    </ProjectDescription>
                                    <a href="https://first-movers.club/" target="_blank"><Button className="mt-4 btn-sm" type="button">CLICK TO OPEN</Button></a>
                                </div>
                            </CardSection>
                        </ProjectCard>

                        <ProjectCard className="col-12 col-sm-12 col-md-12 col-xl-6">
                            <CardSection>
                                <div className="col-12">
                                    <img className="mb-3 img-border" src={curio} />
                                    <ProjectTitle>CurioInfo: Dashboard for CurioInvest</ProjectTitle>
                                    <ProjectTags>#react #nodejs #web3 #alchemy #eth #bsc</ProjectTags>
                                    <ProjectDescription className="mt-2">
                                        CurioInvest is a multi-coin tokenization eco-system.
                                        The dashboard bundles all data into one place.
                                        There is also a calculator to calculate your CGT returns by swapping CUR Tokens for CURV Tokens.
                                    </ProjectDescription>

                                    {/* <div className="row no-gutters mt-3">
                                        <div className="col-6">
                                            <TopTag>Frontend</TopTag>
                                            <InfoTag>React, Bootstrap, SCSS, Styled Components</InfoTag>
                                        </div>
                                        <div className="col-6">
                                            <TopTag>Backend</TopTag>
                                            <InfoTag>Node.js, Express.js, Web3</InfoTag>
                                        </div>
                                    </div>

                                    <div className="row no-gutters mt-3">
                                        <div className="col-6">
                                            <TopTag>Tools</TopTag>
                                            <InfoTag>Apexcharts, Express, BigNumber</InfoTag>
                                        </div>
                                        <div className="col-6">
                                            <TopTag>Blockchain</TopTag>
                                            <InfoTag>Ethereum (ETH), Binance Smart Chain (BSC)</InfoTag>
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        <div className="col-12">
                                            <a href="https://curio-info-app.herokuapp.com/" target="_blank"><Button className="mt-4 btn-sm" type="button">CLICK TO OPEN</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </CardSection>
                        </ProjectCard>

                    </div>

                    {/* <div className="pattern bottom"></div>            */}
                </div>
            </Section>
    )
}

export const ProjectCard = styled.div`
    @media only screen and (min-width: 320px) {
        padding: 0;
    }

    @media only screen and (min-width: 576px) {
        padding: 5px
    }

    @media only screen and (min-width: 768px) {
        padding: 10px 20px;
    }
`

export const ButtonScreenshot = styled.button.attrs({ className: "btn btn-color" })`
        &.btn-color {
            color: ${(props) => props.theme.mode === "light" ? config.header.textColor1 : config.header.color5};
            background: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color3};
          }  

        &.btn-color:hover,
        &.btn-color:active,
        &.btn-color:focus,
        &.btn-color.active {
          color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color3};
          background: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color5};

        }
    `

export const Headline = styled.h3`
    font-family: 'Big Shoulders Display';
    letter-spacing: 2px;
    line-height: 1.15;
    margin-bottom: 10px;
    color: ${(props) => props.theme.mode === "light" ? colors.color3_light : config.header.headlineDark};

    @media only screen and (min-width: 320px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 50px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 50px;
    }
`

export const Title= styled.div`
    color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color3_light };
    margin-bottom: 50px;

    @media only screen and (min-width: 320px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 26px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 26px;
    }

`

const Section = styled.section.attrs({ className: "section" })`
    &.section {
        position: relative;
        display: flex;
        align-items: center; 
        justify-content: center;
        padding: 3em;
        background-color: ${(props) => props.theme.mode === "light" ? config.header.backgroundColor2 : config.header.color5};
    }     
`

const CardSection = styled.section.attrs({ className: "section" })`
    &.section {
        border-radius: 10px;
        background-color: ${(props) => props.theme.mode === "light" ? colors.color3_light : config.header.headlineDark};
        
    }     
    @media only screen and (min-width: 320px) {
        padding-top: 15px;
        padding-bottom: 15px;
        height: auto;
    }

    @media only screen and (min-width: 576px) {
        padding: 25px 10px;
    }

    @media only screen and (min-width: 768px) {
        padding: 20px 5px;
        height: auto;
    }

    @media only screen and (min-width: 1200px) {
        padding: 20px 5px;
        height: 570px;
    }
`

const ProjectTitle = styled.h3`
    margin-bottom: 0;
    color: ${(props) => props.theme.mode === "light" ? colors.color5_light : config.header.color1};

    @media only screen and (min-width: 320px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 22px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 26px;
    }
`

const ProjectTags = styled.div`
    color: ${(props) => props.theme.mode === "light" ? colors.color1_light : config.header.color2};

    @media only screen and (min-width: 320px) {
        font-size: 14px;
        margin-top: 5px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 16px;
    }

`

const ProjectDescription = styled.div`
    color: ${(props) => props.theme.mode === "light" ? colors.color5_light : config.header.textColor4};

    @media only screen and (min-width: 320px) {
        font-size: 16px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 20px;
    }

`

const TopTag = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.mode === "light" ? config.header.textColor2 : config.header.textColor1};
`

const InfoTag = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.mode === "light" ? config.header.textColor1 : config.header.textColor1};
`