export const colors = {
  color1_light: '#f09724',
  color2_light: '#92939b',
  color3_light: '#f4f4f6',
  color4_light: '#041d4d',
  color5_light: '#3f4150',
  color6_light: '#1162ee',

  color1_dark: '#e1c231',
  color2_dark: '#92939b',
  color3_dark: '#f4f4f6',
  color4_dark: '#2d2c32',
  color5_dark: '#191f2b',
};