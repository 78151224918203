import styled from 'styled-components'

import { config } from '../../config'
const { backgroundColorSectionTwo, backgroundColorSectionTwoDark } = config.header

export const SkillSection = styled.section.attrs({
  className: "section-header"
})`
        &.section-header {
            position: relative;
            padding-top: 5rem;
            padding-bottom: 5rem;
            background-color: ${(props) => props.theme.mode === "light" ? backgroundColorSectionTwo : backgroundColorSectionTwoDark};
          }     
    `