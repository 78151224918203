import React, { Component } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import styled from 'styled-components'
import { config } from '../../config'

import {
    CryptoSection,
    CryptoHeadline
} from '.';

class EthereumData extends Component {

    state = {
    }

    componentDidMount() {
        this.fetchData();
        this.getBlockchainData();
    }

    async getBlockchainData() {

        try {

            var staked_ethereum = ''

            const url = 'https://mainnet.infura.io/v3/bb1aa97a6de04de1ba571fa71d702f91';

            const walletAddress = '0x00000000219ab540356cBB839Cbe05303d7705Fa'; // ETH 2.0 Deposit address

            const web3 = new Web3(new Web3.providers.HttpProvider(url));
            var balance = await web3.eth.getBalance(walletAddress).then(function (result) {
                staked_ethereum = result / 10e17;
            });
       
            const DEPOSIT = 32;                     // Validator Deposit (in ETH)
            const UPTIME = 0.98;                    // Validator Uptime (in Percent)
            const SLOT_TIME = 12;                   // Slot Time (in sec)
            const EPOCH_LENGTH = 32;                // Epoch Length (in slots)          
            const BASE_REWARD_FACTOR = 64;          // Base Reward Factor
            const BASE_REWARD_PER_EPOCH = 4;        // Base Rewards per Epoch
            const BASE_REWARD_PROPORTIONAL = 3;     // Base rewards that are proportional to validators online
            const PENALTY = 1 / 64;                 // Penalty for being included one slot late

            // Base reward for full validator (in gwei)
            const BASE_REWARD = 32 * 10 ** 9 * BASE_REWARD_FACTOR / Math.sqrt(staked_ethereum * 10**9) / 4;
            // Online/Offline per-validator reward per epoch (in gwei)
            const ONLINE = BASE_REWARD * BASE_REWARD_PROPORTIONAL * UPTIME + 1 * (0.125 * BASE_REWARD * UPTIME + 0.875 * BASE_REWARD * (UPTIME + UPTIME * (1 - UPTIME) * (1 - PENALTY) + UPTIME * (1 - UPTIME) ** 2 * (1 - 2 * PENALTY)));
            const OFFLINE = BASE_REWARD * BASE_REWARD_PER_EPOCH;
            // Epoch per Year
            const EPOCH_PER_YEAR = (60 * 60 * 24 * 365.242) / (SLOT_TIME * EPOCH_LENGTH)
            const STAKER_REWARD = ((ONLINE * UPTIME - OFFLINE * (1 - UPTIME)) * EPOCH_PER_YEAR / 10**9 / DEPOSIT) * 100;

            this.setState({
                staked_ethereum: staked_ethereum.toLocaleString(),
                staker_reward: STAKER_REWARD.toFixed(2),
            });
            

        } catch (error) {
            console.log('Error:' + error);
        }

    }

    async fetchData() {
        try {
            let metrics = await axios.get('https://data.messari.io/api/v1/assets/ETH/metrics');

            var circulating_supply = metrics.data.data.supply.circulating;
            circulating_supply = Math.round(circulating_supply * 1) / 1

            this.setState({
                marketcap: metrics.data.data.marketcap.current_marketcap_usd.toLocaleString(),
                circulating_supply: circulating_supply.toLocaleString()
            });

        } catch (error) {
            console.log('Error:' + error);
        }

    }



    render() {

        return (
            <Section>

                <div className="row">

                    <div className="col-12">

                    <div className="row justifycontent">
                    <div className="col-12">
                        <Headline className="text-center">ETHEREUM 2.0</Headline>
                        <Title className="text-center">Ethereum is a world computer and a smart contract platform which allows entities to build decentralized applications.
                            Ethereum is in transition phase from ETH 1.0 to ETH 2.0 - There are 3,67 % of the total supply locked in beacon chain.
                        </Title>
                    </div>
                    </div>                       
                    <div className="row justifycontent">

                        <div className="col-3">
                            <Card>
                                <div class="card-header">
                                    <div className="tx-14">Circulating Supply ETH</div>
                                    <h5>{this.state.circulating_supply}</h5>
                                </div>
                            </Card>
                        </div>

                        <div className="col-3">
                            <Card>
                                <div class="card-header">
                                    <div className="tx-14">Total ETH locked in 2.0</div>
                                    <h5>{this.state.staked_ethereum}</h5>
                                </div>
                            </Card>
                        </div>

                        <div className="col-2">
                            <Card>
                                <div class="card-header">
                                    <div className="tx-14">ROI in %</div>
                                    <h5>{this.state.staker_reward}</h5>
                                </div>
                            </Card>
                        </div>

                    </div>

                    </div>

                </div>

            </Section >
        )
    }
}

export const Card = styled.section.attrs({
    className: "card"
})`
    &.card {
        color: ${(props) => props.theme.mode === "light" ? config.header.cardBg : config.header.color1};
        background-color: ${(props) => props.theme.mode === "light" ? config.header.cardBg : config.header.color4};
        margin-bottom: 15px;
        padding: 10px;
    }     
`

const Section = styled.section.attrs({ className: "section" })`
    &.section {
        position: relative;
        display: flex;
        align-items: center; 
        justify-content: center;
        padding: 6em;
        background-color: ${(props) => props.theme.mode === "light" ? config.header.color4 : config.header.color4_dark};
    }     
`

export const Headline = styled.h3`
  font-family: 'Big Shoulders Display';
  letter-spacing: 2px;
  line-height: 1.15;
  font-size: 2em;
  margin-top: 10px;
  color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.headlineDark};

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`

export const Title = styled.div`
    color: ${(props) => props.theme.mode === "light" ? config.header.title : config.header.color3};
    font-size: 18px;
    margin-bottom: 50px;
    @media only screen and (max-width: 768px) {
        font-size: 2em;
    }
`

export default EthereumData