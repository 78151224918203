import React from 'react'
import { ThemeProvider } from "styled-components";
import Router from './Router';
import Toggle from "./Components/Theme/Toggler";
import { GlobalStyles } from "./Components/Theme/GlobalStyles";
import { lightTheme, darkTheme } from "./Components/Theme/Theme";
import  { useDarkMode } from "./Components/Theme/useDarkMode";

const Application = () => {
  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;


  return (
    <>
        <ThemeProvider theme={themeMode} toggleTheme={themeToggler}>
          <GlobalStyles />
          <Router />
          {/* <Toggle theme={theme} toggleTheme={themeToggler} /> */}
        </ThemeProvider>
    </>
  )
};

export default Application;