import React from 'react';
import styled from 'styled-components';
import { colors } from '../Theme/colors';

import EthereumPrice from './Ethereum-ticker/EthereumPrice';

export const Nav = () => {
    return (

        <div className="container">
            <div className="d-flex justify-content-between pt-4">
                <h2 className="soulman-logo m-0">
                    <NavLogo>soulmans (code)</NavLogo>
                </h2>
                <div className="pt-1">
                    <Ethereum href="https://www.cryptocompare.com/coins/eth/overview/USD" target="_blank" className="ethereum"><EthereumPrice /></Ethereum>
                </div>
            </div>
        </div>
    )
}

export const BootstrapWrapper = styled.nav.attrs({
    className: "navbar navbar-expand-lg navbar-light p-0"
})`
        &.navbar-custom .navbar-brand,
        .navbar-custom .navbar-text {
            color: ${(props) => props.theme.mode === "light" ? colors.color5_light : colors.color5_light};
        }


        /* change the link color */
        &.navbar-custom .navbar-nav .nav-link {
            color: ${(props) => props.theme.mode === "light" ? colors.color5_light : colors.color5_light};
        }

        /* change the color of active or hovered links */
        &.navbar-custom .nav-item.active .nav-link,
        &.navbar-custom .nav-item:hover .nav-link {
            color: ${(props) => props.theme.mode === "light" ? colors.color5_light : colors.color5_light};
        }     
    `

export const Ethereum = styled.a`
  color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color2_dark} !important;
  text-transform: uppercase !important;
  font-weight: 400;

  :hover {
    color: ${props => props.theme.mode === 'light' ? colors.color3_light : colors.color1_dark} !important;
    text-decoration: none !important;
    opacity: 0.7;
  }
`

// NavLogo
export const NavLogo = styled.div`
    text-decoration: none !important;
    letter-spacing: 0px;
    color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color3_dark};

    :hover {
    color: ${props => props.theme.mode === 'light' ? colors.color3_light : colors.color1_dark};
    opacity: 0.7;
    }
`