import React from 'react'

import {
    HeaderContactTitleMain,
    HeaderContactTitle,
    HeaderContactSubtitle,
    HeaderContactSection,
} from '.'
import { config } from "../../config"

export const HeaderContact = () => {
    return (
        <HeaderContactSection>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <HeaderContactTitleMain className="text-center">
                            Contact
                        </HeaderContactTitleMain>
                        <HeaderContactTitle className="text-center">
                            Have any questions? I would love to hear from you.
                        </HeaderContactTitle>
                        <HeaderContactSubtitle className="text-center">
                            {config.subtitle}
                        </HeaderContactSubtitle>
                    </div>
                </div>
            </div>
        </HeaderContactSection>
    )
}
