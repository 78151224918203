import React from 'react'
import styled from 'styled-components'
import { config } from '../../config'
import { colors } from '../Theme/colors';
import {
    NavLogo,
} from '../Nav/'

export const Footer = () => {
    return (
        <FooterSection>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="soulman-logo text-center">
                            <NavLogo>soulmans (code)</NavLogo>
                        </h2>
                    </div>
                    {/* <div className="col-4 text-center">
                        <FooterSubtitle>
                            2021
                        </FooterSubtitle>
                    </div>
                    <div className="col-4 text-right">
                        <FooterSubtitle>
                            soulmans (code)
                        </FooterSubtitle>
                    </div>                                         */}
                </div>
            </div>
        </FooterSection>
    )
}

export const FooterSection = styled.section.attrs({
    className: "section-header"
})`
    &.section-header {
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: ${(props) => props.theme.mode === "light" ? colors.color6_light : config.header.color4};
    }     
`

export const Copyright = styled.h1`
  font-size: 14px;
  color: ${(props) => props.theme.mode === "light" ? config.header.titleColor : config.header.color2};
`

export const FooterTitleMain = styled.h1`
  font-family: 'Big Shoulders Display';
  line-height: 1.15;
  font-size: 5em;
  padding: 15px 25px 0 25px;
  margin-top: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.mode === "light" ? config.header.titleColor : config.header.titleColorDark};

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`

export const FooterTitle = styled.h2`
  font-family: 'Big Shoulders Display';
  line-height: 1.15;
  font-size: 14px;
  padding: 15px 25px 25px 25px;
  border-radius: 10px;
  color: ${(props) => props.theme.mode === "light" ? config.header.titleColor : config.header.titleColorDark};

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`

export const FooterSubtitle = styled.p`
  font-weight: 400;
  color: ${(props) => props.theme.mode === "light" ? config.header.subtitleColor : config.header.subtitleColorDark};
  font-size: 1.0em;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`
