import React, { Component } from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { config } from '../../config'
import { colors } from '../Theme/colors'

const linkIcon = <FontAwesomeIcon icon={faAngleDoubleRight} size='2x' color="green" />

export const DevelopOn = () => {

        return (
            <CryptoSection>

                <div className="container">

                    <div className="row justifycontent mb-3">
                        <div className="col-12 text-center">
                            <Headline className="text-center">BUILDING (D)APPS ON</Headline>
                        </div>                  
                    </div>    

                    <div className="row justifycontent">
                        <div className="col-8 col-sm-7 col-md-4 col-xl-3">
                            <Card>
                                <div class="card-header">
                                    <Coin>ETH</Coin>
                                    <CoinName>Ethereum</CoinName>
                                </div>
                            </Card>
                        </div>
                        <div className="col-8 col-sm-7 col-md-4 col-xl-3">
                            <Card>
                                <div class="card-header">
                                    <Coin>MATIC</Coin>
                                    <CoinName>Polygon</CoinName>
                                </div>
                            </Card>
                        </div>
                        <div className="col-8 col-sm-7 col-md-4 col-xl-3">
                            <Card>
                                <div class="card-header">
                                    <Coin>BSC</Coin>
                                    <CoinName>Binance Smart Chain</CoinName>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <Title className="text-center">+ NFT Standard (ERC721)</Title>

                </div>

            </CryptoSection >
        )
    
}

export const Card = styled.section.attrs({
    className: "card"
})`
    &.card {
        color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color5_dark};
        background-color: ${(props) => props.theme.mode === "light" ? colors.color6_light : colors.color6_dark};
        margin-bottom: 15px;
          border-radius: 6px;
    }     
`

export const Headline = styled.h3`
  font-family: 'Big Shoulders Display';
  letter-spacing: 2px;
  line-height: 1.15;
  margin-top: 10px;
  margin-bottom: 50px;

  color: ${(props) => props.theme.mode === "light" ? colors.color5_light : config.header.headlineDark};

    @media only screen and (min-width: 320px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 40px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 50px;
    }

`

export const Title= styled.div`

color: ${(props) => props.theme.mode === "light" ? config.header.title : config.header.color3};
font-size: 26px;
margin-top: 20px;
margin-bottom: 50px;

@media only screen and (min-width: 320px) {
    font-size: 22px;
}

@media only screen and (min-width: 576px) {
    font-size: 24px;
}

`

export const CryptoSection = styled.section.attrs({
    className: "section-header"
})`
        &.section-header {
            position: relative;
            padding-top: 3rem;
            padding-bottom: 3rem;
            background-color: ${(props) => props.theme.mode === "light" ? config.header.backgroundColorSectionThree : config.header.backgroundColor2Dark};
          }     
    `

export const Coin = styled.h2`
  letter-spacing: 3px;
  line-height: 1.15;
  text-align: center;
  margin-bottom: 0px;
  color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color3};

    @media only screen and (min-width: 320px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 60px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 46px;
    }
`

export const CoinName = styled.div`
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color2};

    @media only screen and (min-width: 320px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 14px;
    }
    @media only screen and (min-width: 992px) {
        font-size: 20px;
    }
`
