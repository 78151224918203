import styled from 'styled-components'

import { config } from '../../config'
const { title, titleDark } = config.header

export const SkillTitle= styled.p`

  color: ${(props) => props.theme.mode === "light" ? title : titleDark};
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`