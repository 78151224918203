import React, { Component } from "react";

class EthereumPrice extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            price: {},
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("NETWORK RESPONSE ERROR");
                }
            })
            .then((data) => {
                this.setState({
                    price: data.USD,
                    loading: false,
                });
            })
            .catch((error) => this.setState({ error, loading: false }));
    }

    render() {
        const { loading, price } = this.state;
        const output = loading ? "LOADING..." : "Ξ " + price + " $";
        return (output);
    }
}

export default EthereumPrice;