import { colors } from "./Components/Theme/colors"

export const config = {
  // Your Github Converted Token To Know How To Get Your Token Look at Readme.md
  githubConvertedToken: "NTA2YTVmZWM1ZGZkNGIzNDA2OWZmNDc5MzZhMTVmMmY1MGM4YmU0OA==",

  // Your Github UserName
  githubUserName: "mrdespoiler",

  // Your Github Repo Name Where You Have your issues as Blog
  githubRepo: "react-personal-blog-github",

  // Set it to true if you have a Github app to add to this project
  // and fill the client ID & secret
  enableOAuth: true,
  OAuthClientID: 'Iv1.f9bce622577c0eec',
  OAuthSecret: 'f711b2af08767a421acc23f694373230da78f51c',

  // Your Personal Blog Title
  title: "Full Stack Web Development" ,

  // Your Personal Blog Subtitle
  subtitle: "#allThingsCrypto #Ethereum #ERC20 #DeFi #NFT #DEX",

  // Header customization
  header: {
    logoColor: '#fafafa',
    logoColorDark: colors.color1_dark,
    logoColorHover: '#f99b48',
    logoColorHoverDark: colors.color2_dark,

    backgroundColor: '#959ea7', // can be a CSS gradient
    backgroundColorDark: colors.color5_dark,

    color1: colors.color1_dark,
    color2: colors.color2_dark,
    color3: colors.color3_dark,
    color4: colors.color4_dark,
    color5: colors.color5_dark,


    backgroundColor1: '#094bbe',
    backgroundColor1Dark: colors.color5_dark,
    backgroundColor2: '#5c69e0',
    backgroundColor2Dark: colors.color4_dark,
    backgroundColor3: '#f5f8fb',
    backgroundColor3Dark: colors.color1_dark,

    buttonBackgroundDark: colors.color1_dark,
    buttonBackgroundHoverDark: colors.color4_dark,
    buttonTextDark: colors.color5_dark,
    buttonTextHoverDark: colors.color3_dark,

    // Navigation background colors
    navBackgroundColor: '#094bbe',
    navBackgroundColorDark: '#191f2b',
    // Navigation Link Colors
    navLinkColor: '#fafafa',
    navLinkColorDark: colors.color2_dark,
    navLinkColorHover: '#f99b48',
    navLinkColorHoverDark: colors.color1_dark,
    webDeveloperColor: '#f99b48',
    webDeveloperColorDark: colors.color1_dark,

    titleColor: '#fafafa',
    titleColorDark: colors.color3_dark,

    headline: '#094bbe',
    headlineDark: colors.color1_dark,
    title: '#7f6c6c', 
    titleDark: '#fafafa',
    subtitle: '#c1bdbd',
    subtitleDark: '#a59c9c',

    ethereumIcon: '#a59c9c',
    ethereumIconDark: '#fafafa',


    // Navigation Ethereum Colors
    // navEthereumColor: '#c7c7c7',
    // navEthereumColorDark: '#c7c7c7',
    // navEthereumColorHover: '#fafafa',
    // navEthereumColorHoverDark: '#fafafa',
    // Navigation WebDev Highlight Colors
  },
};