import styled from 'styled-components'

import { config } from '../../config'
const { titleBackgroundColor, titleBackgroundColorDark, titleColor, titleColorDark } = config.header

export const HeaderContactTitleMain = styled.h1`
  font-family: 'Big Shoulders Display';
  line-height: 1.15;
  font-size: 5em;
  padding: 15px 25px 0 25px;
  margin-top: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.mode === "light" ? titleColor : titleColorDark};

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`