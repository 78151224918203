import styled from 'styled-components'

import { config } from '../../config'
const { navBackgroundColor, navBackgroundColorDark } = config.header

export const HeaderContactSection = styled.section.attrs({
  className: "section-header"
})`
        &.section-header {
            position: relative;
            padding-top: 6rem;
            padding-bottom: 15rem;
            background-color: ${(props) => props.theme.mode === "light" ? navBackgroundColor : navBackgroundColorDark};
        }     
    `