import styled from 'styled-components'

import { config } from '../../config'
const { subtitle, subtitleDark } = config.header

export const SkillSubtitle = styled.p`

  color: ${(props) => props.theme.mode === "light" ? subtitle : subtitleDark};
  font-size: 16px;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`