import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faAngular, faNodeJs, faBootstrap } from '@fortawesome/free-brands-svg-icons'
import { config } from '../../config'
import { colors } from '../Theme/colors'

// import react from './react.png'
import redux from './img/redux.png'
import mongodb from './img/mongodb.png'
import expressjs from './img/expressjs.png'
import nodejs from './img/nodejs.png'
import truffle from './img/truffle.png'
import ganache from './img/ganache.png'
import mocha from './img/mocha.png'
import react from './img/react.png'
import bootstrap from './img/bootstrap.png'

// const react = <FontAwesomeIcon icon={faReact} />
// const angular = <FontAwesomeIcon icon={faAngular} />
// const bootstrap = <FontAwesomeIcon icon={faBootstrap} />
// const nodejs = <FontAwesomeIcon icon={faNodeJs} />

export const Skills = () => {
    return (
        <SkillSection>

            <div className="container">

                <div className="row justifycontent mb-3">
                    <div className="col-12 text-center">
                        <Headline className="text-center">TECH STACK</Headline>
                        <Title className="text-center">Technologie I use to make things happen on Web3</Title>
                    </div>
                </div> 

                <div className="row">

                    <div className="col-12">

                        <div className="row justifycontent">
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>React.js</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Node.js</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Web3</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Bootstrap</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>MongoDB</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>thirdWeb</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Infura</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Alchemy</Card>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <Card>Python</Card>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </SkillSection>

            /* <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SkillHeadline className="text-center">MY DEVELOPMENT STACK</SkillHeadline>
                        <SkillTitle className="text-center">I use the power of the latest technologies from modern HTML5 to Web 3.0</SkillTitle>
                    </div>
                </div>
                <div className="row">

                    <div className="col-3"></div>

                    <div className="col-1 valign">
                        <div className="skill-icon"><img src={react} class="img-fluid" width="50px" alt="Redux" /></div>
                    </div>

                    <div className="col-1 valign">
                        <div className="skill-icon"><img src={redux} class="img-fluid" width="50px" alt="Redux" /></div>
                        <div className="skill-icon"><img src={bootstrap} class="img-fluid" width="50px" alt="Bootstrap" /></div>                   
                    </div>

                    <div className="col-1 valign">
                        <div className="skill-icon"><img src={nodejs} width="50px" alt="Node.js" /></div>
                        <div className="skill-icon"><img src={expressjs} class="img-fluid" width="50px" alt="Express.js" /></div>
                        <div className="skill-icon"><img src={mongodb} class="img-fluid" width="50px" alt="MongoDB" /></div>                      
                    </div>

                    <div className="col-1 valign">
                        <div className="skill-icon"><img src={truffle} class="img-fluid" width="50px" alt="Truffle Suite" /></div>
                        <div className="skill-icon"><img src={ganache} width="50px" alt="Ganache" /></div> 
                    </div>

                    <div className="col-1 valign">
                        <div className="skill-icon"><img src={mocha} width="50px" alt="Mocha" /></div> 
                    </div>

                    <div className="col-3"></div>

                </div>
                <div className="row">
                    <div className="col-12">
                    </div>
                </div>                
            </div> */

        
    )
}

const SkillSection = styled.section.attrs({ className: "section" })`
    &.section {
        position: relative;
        display: flex;
        align-items: center; 
        justify-content: center;
        
        background-color: ${(props) => props.theme.mode === "light" ? colors.color3_light : config.header.color4_dark};
    } 
    @media only screen and (min-width: 320px) {
        padding: 2em;
    }

    @media only screen and (min-width: 576px) {
        padding: 3em;
    }

    @media only screen and (min-width: 768px) {
        padding: 4em;
    }
`

export const Card = styled.section.attrs({
    className: "card"
})`
    &.card {
        color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color5_dark};
        background-color: ${(props) => props.theme.mode === "light" ? colors.color6_light : colors.color6_dark};
        margin-bottom: 15px;
        border-radius: 6px;
        text-align: center;
    }   
    
    
    @media only screen and (min-width: 320px) {
        font-size: 16px;
        padding: 5px 0;
    }

    @media only screen and (min-width: 576px) {
        font-size: 18px;
        padding: 8px 0;
    }

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`

export const Headline = styled.h3`
  font-family: 'Big Shoulders Display';
  letter-spacing: 2px;
  line-height: 1.15;
  margin-top: 10px;
  margin-bottom: 10px;

  color: ${(props) => props.theme.mode === "light" ? colors.color5_light : config.header.headlineDark};

    @media only screen and (min-width: 320px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 40px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 50px;
    }

`

export const Title = styled.div`

color: ${(props) => props.theme.mode === "light" ? config.header.title : config.header.color3};
font-size: 26px;
margin-bottom: 20px;

@media only screen and (min-width: 320px) {
    font-size: 22px;
}

@media only screen and (min-width: 576px) {
    font-size: 24px;
}

`

export const CryptoSection = styled.section.attrs({
    className: "section-header"
})`
        &.section-header {
            position: relative;
            padding-top: 3rem;
            padding-bottom: 3rem;
            background-color: ${(props) => props.theme.mode === "light" ? config.header.backgroundColorSectionThree : config.header.backgroundColor2Dark};
          }     
    `

export const Coin = styled.h2`
  letter-spacing: 3px;
  line-height: 1.15;
  text-align: center;
  margin-bottom: 0px;
  color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color3};

    @media only screen and (min-width: 320px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 60px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 46px;
    }
`

export const CoinName = styled.div`
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.mode === "light" ? config.header.buttonColorPrimary : config.header.color2};

    @media only screen and (min-width: 320px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 14px;
    }
    @media only screen and (min-width: 992px) {
        font-size: 20px;
    }
`