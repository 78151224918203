import React, { useState, useEffect } from "react";

import { Nav } from "../Components/Nav";
import { WelcomeScreen } from "../Components/WelcomeScreen";
import { Projects } from "../Components/Projects";
import { Skills } from '../Components/Skills'
import { Footer } from '../Components/Footer'
import { Crypto } from '../Components/Crypto'
import { DevelopOn } from '../Components/DevelopOn'


const Blog = () => {

  return (
    <>
      <WelcomeScreen />
      <DevelopOn />
      <Projects />
      <Skills />
      {/* <Crypto /> */}
      <Footer />
    </>
  );
}

export default Blog;
