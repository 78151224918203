import React from 'react'
import styled from 'styled-components'
import { Nav } from "../Nav";
import { Button } from '../Common/Button'
import { config } from "../../config"
import titleSvgHair from './hair.svg'
import { colors } from '../Theme/colors';

export const WelcomeScreen = () => {
    return (
        <>
        <Section className="bg">

            <div className="container">
                <div className="row">
                        <div className="col-12 text-center">
                            <Nav />
                        </div>

                    <div className="col-12 text-center pt-60">
                        <img class="headerIcon" src={ titleSvgHair } alt="soulmans-hair"></img>
                        <Title className="text-center">
                            Hello, I am Soulman
                        </Title>
                        <SubTitle className="text-center">
                            <WebDev>Full Stack Web Developer</WebDev> from Hannover/Germany with an affection for cryptocurrencies & smart contracts
                        </SubTitle>
                        <Info className="text-center mb-5">
                            {config.subtitle}
                        </Info>
                    </div>
                </div>
            </div>
            {/* <div className="pattern bottom"></div>            */}
        </Section>
        </>
    )
}

const Section = styled.section.attrs({ className: "section" })`
    &.section {
        position: relative;
        top: 0px;
        display: flex;
        align-items: center; 
        justify-content: center;
        padding-bottom: 9em;
        background-color: ${(props) => props.theme.mode === "light" ? config.header.navBackgroundColor : config.header.navBackgroundColorDark};
        height: auto;
    }     
`

const Title = styled.h1`
    font-family: 'Big Shoulders Display';
    line-height: 1.15;
    padding: 15px 25px 0 25px;
    margin-top: 10px;
    color: #fafafa;

    @media only screen and (min-width: 320px) {
        font-size: 50px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 60px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 80px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 100px;
    }
`

const SubTitle = styled.h2`
  font-family: 'Big Shoulders Display';
  line-height: 1.15;
  letter-spacing: 1px;
  line-height: 1.5;
  padding: 15px 25px 25px 25px;
  border-radius: 10px;
  color: ${(props) => props.theme.mode === "light" ? colors.color3_light : config.header.titleColorDark};

  @media only screen and (min-width: 320px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 576px) {
    font-size: 30px;
}

@media only screen and (min-width: 768px) {
    font-size: 30px;
}

@media only screen and (min-width: 1200px) {
    font-size: 34px;
}

`

const Info = styled.p`
  font-weight: 400;
  color: ${(props) => props.theme.mode === "light" ? colors.color3_light : config.header.subtitleDark} !important;
  opacity: 0.8;
  line-height: 1.15;

    @media only screen and (min-width: 320px) {
        font-size: 15px;
    }

    @media only screen and (min-width: 576px) {
        font-size: 16px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`

const WebDev = styled.span`
  font-family: 'Big Shoulders Display';
  color: ${(props) => props.theme.mode === "light" ? colors.color4_light : config.header.webDeveloperColorDark} !important;
  opacity: 0.7;
`