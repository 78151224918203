import styled from 'styled-components'

import { config } from '../../config'
const { headline, headlineDark } = config.header

export const SkillHeadline = styled.h3`
  font-family: 'Big Shoulders Display';
  line-height: 1.15;
  font-size: 3.0em;
  color: ${(props) => props.theme.mode === "light" ? headline : headlineDark};

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`