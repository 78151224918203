import React from 'react'
import styled from 'styled-components'

import { config } from '../../config'
import { colors } from '../Theme/colors'

export const Button = styled.button.attrs({ className: "btn btn-color" })`
        &.btn-color {
            color: ${(props) => props.theme.mode === "light" ? colors.color3_light : colors.color5_dark};
            background: ${(props) => props.theme.mode === "light" ? colors.color6_light : colors.color3_dark};
            padding: 7px 30px 7px 30px;
          }  

        &.btn-color:hover,
        &.btn-color:active,
        &.btn-color:focus,
        &.btn-color.active {
          color: ${(props) => props.theme.mode === "light" ? colors.color4_light : colors.color3_dark};
          background: ${(props) => props.theme.mode === "light" ? colors.color1_light : colors.color1_dark};

        }

    @media only screen and (min-width: 320px) {
        &.btn-color {
            font-size: 15px;
          }  

    }

    @media only screen and (min-width: 576px) {
        padding: 5px
    }

    @media only screen and (min-width: 768px) {
        padding: 40px 20px;
    }
    `