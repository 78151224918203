import styled from 'styled-components'

import { config } from '../../config'
import { colors } from '../Theme/colors';
const { logoColor, logoColorDark, logoColorHover, logoColorHoverDark } = config.header;

export const NavLogo = styled.div`
  text-decoration: none !important;
  letter-spacing: 0px;
  color: ${(props) => props.theme.mode === "light" ? logoColor : logoColorDark};
  

  :hover {
    color: ${props => props.theme.mode === 'light' ? colors.color3_light : logoColorHoverDark};
    opacity: 0.7;
  }
`